import { PicProfileFacebook } from '../../interfaces/facebook-login-user.interface';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerDto } from '../../models/customer/customer.dto.model';
import { Customer } from '../../models/customer/customer.model';
import { SuccessHttpResponse } from '../../models/http.model';
import { PushRelationService } from '../push-relation/push-relation.service';
import { AccessToken } from '@capacitor-community/facebook-login';
import { FacebookLoginResponse } from '../../interfaces/facebook-login-user.interface';
import { NavController } from '@ionic/angular';

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface AccessTokenResponse {
  user: Customer;
  accessToken: string;
  refreshToken: string;
}

export interface Payload {
  id: string;
  exp: number;
}

export interface ResetPasswordRequest {
  customerId: string;
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUserSubject: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);
  public currentUser: Observable<Customer>;

  constructor(
    private http: HttpClient,
    private pushRelationService: PushRelationService,
    private navCtrl: NavController) {
    this.currentUserSubject.next(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Customer {
    return this.currentUserSubject.value;
  }

  registerNewUser(user: CustomerDto): Observable<AccessTokenResponse> {
    return this.http.post<AccessTokenResponse>(`${environment.api}auth/register`, user);
  }

  loginWithEmail(loginCredentials: LoginCredentials): Observable<AccessTokenResponse> {
    return this.http.post<AccessTokenResponse>(`${environment.api}auth/login/client`, loginCredentials)
      .pipe(map(res => this.setSession(res)));
  }

  getCustomerByEmailSocial(customerEmail: string): Observable<AccessTokenResponse> {
    return this.http.get<AccessTokenResponse>(`${environment.api}auth/login/social/${customerEmail}`);
  }

  resetPassword(email: string): Observable<any> {
    return this.http.get<any>(`${environment.api}mailer/recovery/customer/${email}`);
  }
  
  getFacebookLoginUser(token: AccessToken): Observable<FacebookLoginResponse> {
    const url = `https://graph.facebook.com/v14.0/me?fields=id%2Clast_name%2Cname%2Cemail&access_token=${token.token}`;
    return this.http.get<FacebookLoginResponse>(url);
  }

  getPicProfileFromFacebook(userId: string, token: AccessToken): Observable<PicProfileFacebook> {
    const url = `https://graph.facebook.com/${userId}?fields=picture.width(720).height(720)&access_token=${token.token}`;
    return this.http.get<PicProfileFacebook>(url);
  }

  setSession(authResult: AccessTokenResponse) {
    const payload = this.decodeToken(authResult.accessToken);
    localStorage.setItem('accessToken', authResult.accessToken);
    localStorage.setItem('refreshToken', authResult.refreshToken);
    localStorage.setItem('expires_at', payload.expiration);
    this.currentUserSubject.next(authResult.user);
    return authResult;
  }

  decodeToken(token: string): any {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload;
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('filteredEmployee');
    this.currentUserSubject.next(null);
    this.pushRelationService.removeRelation(localStorage.getItem('osp'));
    this.navCtrl.navigateBack(['tabs/home']);
  }

  refreshToken(): Observable<AccessTokenResponse>{
    return this.http.get<AccessTokenResponse>(`${environment.api}auth/refresh/customer`);
   }
 
   logOutreq(): Observable<string>{
     return this.http.get<string>(`${environment.api}auth/logout/customer`);
    }
  getToken(): string {

  return localStorage.getItem('refreshToken');
}
getTokenAccess(): string {

  return localStorage.getItem('accessToken');
}
}

