import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

import { CheckPhoneGuard } from './core/guards/check-phone.guard';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [CheckPhoneGuard],
  },
  {
    path: ':url',
    loadChildren: () =>
      import(
        './pages/home/commerce/commerce-detail/commerce-detail.module'
      ).then((m) => m.CommerceDetailPageModule),
    canActivate: [CheckPhoneGuard],
  },
  {
    path: 'page/profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'page/bookings',
    loadChildren: () =>
      import('./pages/bookings/bookings.module').then((m) => m.BookingsModule),
    canLoad: [AuthGuard],
    canActivate: [CheckPhoneGuard],
  },
  {
    path: 'page/login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    //canActivate: [AutoLoginGuard]
  },
  {
    path: 'page/register',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'page/forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'page/404-commerce-not-found',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundPageModule
      ),
  },
  { path: '**', redirectTo: '/404-commerce-not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
