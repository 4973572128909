import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Customer } from '../models/customer/customer.model';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  apiUrl = environment.api;

  constructor(private http: HttpClient) { }

  init(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const loggedUser: Customer = JSON.parse(localStorage.getItem('currentUser'));
      if (loggedUser) {
        this.http.get((`${this.apiUrl}customer/check-if-exist/${loggedUser.uuid}`))
          .toPromise()
          .then((res: boolean) => {
            if (res) {
              resolve(true);
            } else {
              localStorage.removeItem('currentUser');
              localStorage.removeItem('id_token');
              localStorage.removeItem('expires_at');
              resolve(true);
            }
          });
      } else {
        resolve(true);
      }
    });
  }
}
