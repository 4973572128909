import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Customer } from '../models/customer/customer.model';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(
    private authService: AuthService,
    private navCtrl: NavController
  ) {}
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const loggedCustomer = localStorage.getItem('accessToken');
    if (loggedCustomer !== null) {
      return true;
    } else {
      this.navCtrl.navigateBack(['page/login']);
      return false;
    }
  }
}
