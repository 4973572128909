import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HammerModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { RequestInterceptor } from './core/interceptors/request.interceptor';
import { SwiperModule } from 'swiper/angular';
import { registerLocaleData } from '@angular/common';
import { NgHttpLoaderModule } from 'ng-http-loader';
// Importar locales
import localES from '@angular/common/locales/es';
import { StarRatingModule } from 'angular-star-rating';
import { InitService } from './core/services/init.service';
import { IonIntlTelInputModule } from 'ion-intl-tel-input';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { WebHeaderModule } from './shared/components/web-header/web-header.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { RefreshTokenInterceptor } from './core/interceptors/auth.interceptor';
import { VersionInterceptor } from './core/interceptors/version.interceptor';

export const servicesOnRun = (initService: InitService) => () =>
  initService.init();

registerLocaleData(localES, 'es');

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ mode: 'ios', animated: false }),
    StarRatingModule.forRoot(),
    AppRoutingModule,
    HammerModule,
    HttpClientModule,
    SwiperModule,
    IonIntlTelInputModule,
    NgHttpLoaderModule.forRoot(),
    WebHeaderModule,
    FooterModule,
  ],
  providers: [
    CallNumber,
    Keyboard,
    {
      provide: APP_INITIALIZER,
      useFactory: servicesOnRun,
      multi: true,
      deps: [InitService],
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
