import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Customer } from './core/models/customer/customer.model';
import { AuthService } from './core/services/auth/auth.service';

import { SpinnerComponent } from './shared/components/spinner/spinner.component';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public spinnerComponent = SpinnerComponent;
  constructor(private authService: AuthService) {

    const loggedCustomer: Customer = JSON.parse(localStorage.getItem('currentUser'));

    if (loggedCustomer !== null) {


      this.authService.currentUserSubject.next(loggedCustomer);

    }
  }
}
