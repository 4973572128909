import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Customer } from 'src/app/core/models/customer/customer.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-web-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.scss'],
})
export class WebHeaderComponent implements OnInit {
  @Input() invert = true;
  @Output() navigateToEmitter: EventEmitter<string> = new EventEmitter();

  public isLogged = false;

  constructor(private navCtrl: NavController, public authService: AuthService,) { }

  ngOnInit() {
    const loggedCustomer: Customer = JSON.parse(localStorage.getItem('currentUser'));
    if (loggedCustomer !== null) {
      this.isLogged = true;
    } else {
      this.isLogged = false;
    }
  }


  navigateTo(path: string) {
    this.navCtrl.navigateRoot(path);
  }

}
