/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Aquí puedes establecer la versión que deseas enviar en el header
    const version = '2.0.4';

    // Clonar la solicitud y agregar el encabezado de versión
    const modifiedRequest = request.clone({
      setHeaders: {
        'X-App-Version': version,
      },
    });

    // Continuar con la solicitud modificada
    return next.handle(modifiedRequest);
  }
}
