import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Customer } from '../models/customer/customer.model';

@Injectable({
  providedIn: 'root'
})
export class CheckPhoneGuard implements CanActivate {
  constructor(private navCtrl: NavController) {

  }
  canActivate(): boolean {
    const loggedCustomer: Customer = JSON.parse(localStorage.getItem('currentUser'));
    if (loggedCustomer) {
      if (loggedCustomer && loggedCustomer.phone !== '') {
        return true;
      } else {
        this.navCtrl.navigateRoot(['/page/profile/customer-data', { skipTabChanges: true }]);
        return false;
      }
    }
    return true;
  }

}
